import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import PreviewCompatibleImage from "../PreviewCompatibleImage"

const MapAustralia = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/map-australia" } }
      ) {
        frontmatter {
          subTitle
          mapImageAlt
          mapImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const { subTitle, mapImageAlt, mapImage } = data.markdownRemark.frontmatter

  return (
    <div className="container section-gap">
      <h2 className="d-block text-center font-bold mb-3">
        We Operate
        <span className="bg-accent d-inline-block text-white px-3 py-1 my-3 mx-2">
          Australia
        </span>
        Wide
      </h2>
      <h3 className="d-block font-bold text-center mb-5">{subTitle}</h3>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-9 col-xl-7">
          <PreviewCompatibleImage src={mapImage} alt={mapImageAlt} />
        </div>
      </div>
    </div>
  )
}

export default MapAustralia
