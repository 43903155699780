import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage"
import FeaturedCarousel from "../../components/FeaturedCarousel"
import PricingTable from "../../components/PricingTable"
import FreeDiscussion from "../../components/FreeDiscussion"
import WhyUse from "../../components/WhyUse"
import Steps from "../../components/Steps"
import ReviewTestimonials from "../../components/ReviewTestimonials"
import InfoSection from "../../components/InfoSection"
import LegalTeam from "../../components/LegalTeam"
import HeroSection from "../../components/HeroSection"
import MapAustralia from "../../components/MapAustralia"
import CharitiesCarousel from "../../components/CharitiesCarousel"

import { GoCheck } from "react-icons/go"
import { DownloadBanner } from "../../components/DownloadBanner"

const WillsOnline = ({ data, path }) => {
  const {
    heroSection,
    informationSection,
    freeDiscussionSection,
    createYourWillOnlineSection,
    whyNeedWillSection,
  } = data.markdownRemark.frontmatter

  return (
    <Layout hasSubNav={true} navId="willsOnline" loadMap={false}>
      <SEO
        path={path}
        title="How to Make a Will | Make a Will online Australia-wide"
        description="Need help in creating a will? Get a legally valid, Lawyer approved will. Decide who gets your assets and looks after your children."
        image="/img/wills.jpeg"
      />

      {/* HERO */}
      <HeroSection
        className="subHeader"
        id="wills-online-hero"
        formButtonName="wills-online"
        bgImage={heroSection.bgImage}
      >
        <div className="d-flex flex-column mb-5">
          <h1 className="font-black">
            Get Your{" "}
            <span className="d-block">
              <span className="emphasize-text">Will Prepared</span>
            </span>
          </h1>
        </div>
      </HeroSection>

      <section className="bg-light">
        <ReviewTestimonials />
      </section>

      <section className="bg-white">
        <FeaturedCarousel />
      </section>

      {/* <section className="bg-light" id="wills-online-create">
        <div className="container section-gap">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 mb-5">
              <h2 className="text-center font-bold mb-3">
                Create Your{" "}
                <span className="d-inline-block bg-accent text-white px-3 py-2 m-2">
                  Will
                </span>
              </h2>
              <h3 className="text-center">
                {createYourWillOnlineSection.subTitle}
              </h3>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className="d-flex w-100 justify-content-center">
                <PreviewCompatibleImage
                  src={createYourWillOnlineSection.laptopImage}
                  alt={createYourWillOnlineSection.laptopImageAlt}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* STEPS - How Online Wills Work */}
      {/* <section className="bg-white">
        <Steps colCount={3} stepsName="wills">
          <h2 className="font-bold text-center mb-5 mb-sm-7 d-block">
            How
            <span className="d-inline-block bg-accent px-3 py-2 m-2 text-white">
              Online Wills
            </span>
            Works
          </h2>
        </Steps>
        <div className="container section-gap pt-0">
          <div className="d-flex justify-content-center">
            <Link to="/make-a-will-online/" className="btn btn-primary btn-lg">
              Start Making Your Will Now
            </Link>
          </div>
        </div>
      </section> */}

      <section className="bg-light">
        <InfoSection infoList={informationSection.list}>
          <div className="d-flex mb-5 mb-sm-7 flex-column align-items-center">
            <h2 className="font-bold mb-4">
              Why Get a <span className="bg-accent px-3 text-white">Will?</span>
            </h2>
            <h3 className="font-semibold text-center">
              These are our top 5 reasons why you need to get Will.
            </h3>
          </div>
        </InfoSection>
      </section>

      <section className="bg-white">
        <div className="container section-gap">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9">
              <h2 className="text-center font-bold mb-5 mb-sm-7">
                Why Do I Need a
                <span className="d-inline-block bg-accent px-3 py-2 m-2 text-white">
                  Will?
                </span>
              </h2>

              <div className="d-flex flex-column justify-content-center">
                {whyNeedWillSection.reasonsList.map((reason, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <GoCheck
                        className="flex-shrink-0 text-accent"
                        style={{ fontSize: "2rem" }}
                      />
                      <div className="pl-3">
                        <span className="font-size-lg-2 font-semibold font-body">
                          {reason}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-light">
        <WhyUse />
      </section> */}

      <section className="bg-white">
        <CharitiesCarousel />
      </section>

      {/* <section className="bg-light">
        <PricingTable tableName="wills-pricing">
          <h2 className="text-center font-bold mb-5">
            Fixed <span className="emphasize-text">Pricing</span>
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Don’t Pay over $300 getting a Will elsewhere!
            </span>
            <span className="d-block d-md-inline-block">
              We charge half that.
            </span>
          </h3>
        </PricingTable>
      </section> */}

      <section className="bg-white">
        <LegalTeam />
      </section>

      <section className="bg-light">
        <FreeDiscussion
          tagline={freeDiscussionSection.tagLine}
          name="wills-online"
        />
      </section>

      <section className="bg-white">
        <MapAustralia />
      </section>
      <DownloadBanner />
    </Layout>
  )
}

export default WillsOnline

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "making-a-will-online/index" } }
    ) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
        createYourWillOnlineSection {
          subTitle
          laptopImageAlt
          laptopImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        informationSection {
          list {
            title
            details
            hasImage
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        whyNeedWillSection {
          reasonsList
        }
        freeDiscussionSection {
          tagLine
        }
      }
    }
  }
`
