import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import PreviewCompatibleImage from "../PreviewCompatibleImage"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./styles.scss"

const CharitiesCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    dotsClass: "testimonialDots",
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/charities-carousel" } }
      ) {
        frontmatter {
          carouselImages {
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  `)

  const { carouselImages } = data.markdownRemark.frontmatter

  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-12 mb-3">
          <h2 className="font-bold d-block text-center mb-4 mb-md-5">
            <span className="d-inline-block bg-accent text-white px-3 py-2 m-2">
              Donate
            </span>
            To Your Favourite
            <span className="d-inline-block bg-accent text-white px-3 py-2 m-2">
              Charities
            </span>
          </h2>
        </div>
        <div className="col-12 mb-5">
          <Slider {...settings}>
            {carouselImages.map(({ image, imageAlt }, i) => {
              return (
                <div key={i}>
                  <PreviewCompatibleImage src={image} alt={imageAlt} />
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default CharitiesCarousel
