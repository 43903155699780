import React from "react"
import { chunk } from "lodash"
import { graphql, useStaticQuery } from "gatsby"
import { GoCheck } from "react-icons/go";

const CheckItem = ({ content }) => {
  return (
    <div className="d-flex justify-content-start align-items-center mb-3">
      <GoCheck className="flex-shrink-0 text-accent" style={{ fontSize: '2rem' }} />
      <div className="pl-3">
        <span className="font-size-lg-2 font-semibold font-body">
          {content}
        </span>
      </div>
    </div>
  )
}

const WhyUse = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { sectionKey: { eq: "sections/why-use" } }) {
        frontmatter {
          sellingPointsList
        }
      }
    }
  `)

  const { sellingPointsList } = data.markdownRemark.frontmatter
  const sellinPointsCount = sellingPointsList.length / 2
  const chunkedSellingPoints = chunk(sellingPointsList, sellinPointsCount)

  return (
    <div className="container">
      <div className="row section-gap justify-content-center align-items-center">
        <h2 className="font-bold text-center mb-5">
          Why Use
          <span className="d-inline-block bg-accent text-white px-3 py-2 m-2">
            Estates Plus ?
          </span>
        </h2>
        <div className="section-main-content col-10 col-md-12 col-xl-9">
          <div className="row">
            {chunkedSellingPoints.map((sellingPoints, i) => {
              return (
                <div className="col-md-6" key={`sellingPoints-${i}`}>
                  <div className="d-flex flex-column">
                    {sellingPoints.map((sellingPoint, i) => {
                      return (
                        <CheckItem
                          key={`sellingPoint-${i}`}
                          content={sellingPoint}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })}
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUse
