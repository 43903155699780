import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import PreviewCompatibleImage from "../PreviewCompatibleImage"

const LegalTeam = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/legal-team" } }
      ) {
        frontmatter {
          description
          imageMobileAlt
          imageAlt
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <div className="container section-gap">
      {!!children ? children : (
        <div className="row text-center justify-content-center mb-5">
          <div className="col-12 mb-4">
            <h2 className="font-bold">
              Our
            <span className="d-inline-block bg-accent text-white px-3 py-2 m-2">
                Legal Team
            </span>
            Are Here To Help
          </h2>
          </div>
          <div className="col-12 col-xl-7">
            <p className="font-semibold">{frontmatter.description}</p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 d-none d-lg-block">
          <PreviewCompatibleImage
            src={frontmatter.image}
            alt={frontmatter.imageAlt}
          />
        </div>
        <div className="col-12 d-lg-none">
          <PreviewCompatibleImage
            src={frontmatter.imageMobile}
            alt={frontmatter.imageMobileAlt}
          />
        </div>
      </div>
    </div>
  )
}

export default LegalTeam
